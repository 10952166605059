import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './components/Header/index.jsx'
import App from './App';
import "/node_modules/slick-carousel/slick/slick.css"; 
import "/node_modules/slick-carousel/slick/slick-theme.css";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import QuemSomos from './pages/QuemSomos';
import Contato from './pages/Contato';
import Estoque from './pages/Estoque';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/quem-somos" element={<QuemSomos />} />
      <Route path="/contato" element={<Contato />} />
      {/* <Route path="estoque" element={<Estoque />} /> */}
      {/* <Route path="invoices" element={<Invoices />} /> */}
    </Routes>
  </BrowserRouter>
  </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


