import "./style.scss"

export default function Marcas () {
    return (
        <>
            <section>
                <div className="logos">
                    <img src="/assets/imagens/misc/logos/audi_logo.png"/>
                    <img src="/assets/imagens/misc/logos/bmw_logo.png"/>
                    <img src="/assets/imagens/misc/logos/chevrolet_logo.png"/>
                    <img src="/assets/imagens/misc/logos/chrysler_logo.png"/>
                    <img src="/assets/imagens/misc/logos/citroen_logo.png"/>
                    <img src="/assets/imagens/misc/logos/dodge_logo.png"/>
                    <img src="/assets/imagens/misc/logos/fiat_logo.png"/>
                    <img src="/assets/imagens/misc/logos/ford_logo.png"/>
                    <img src="/assets/imagens/misc/logos/honda_logo.png"/>
                    <img src="/assets/imagens/misc/logos/hyundai_logo.png"/>
                    <img src="/assets/imagens/misc/logos/jeep_logo.png"/>
                    <img src="/assets/imagens/misc/logos/kn_logo.png"/>
                    <img src="/assets/imagens/misc/logos/lexus_logo.png"/>
                    <img src="/assets/imagens/misc/logos/mercedes_logo.png"/>
                    <img src="/assets/imagens/misc/logos/mini_logo.png"/>
                    <img src="/assets/imagens/misc/logos/mitsubishi_logo.png"/>
                    <img src="/assets/imagens/misc/logos/nissan_logo.png"/>
                    <img src="/assets/imagens/misc/logos/peugeot_logo.png"/>
                    <img src="/assets/imagens/misc/logos/porshe_logo.png"/>
                    <img src="/assets/imagens/misc/logos/ram_logo.png"/>
                    <img src="/assets/imagens/misc/logos/toyota_logo.png"/>
                    <img src="/assets/imagens/misc/logos/volvo_logo.png"/>
                    <img src="/assets/imagens/misc/logos/vw_logo.png"/>
                </div>
            </section>
        </>
    )
};