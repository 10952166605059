import React, { useEffect } from 'react'
import "./style.scss"
import Glide from '@glidejs/glide'

const sliderConfiguration = {
    gap: 0,
    perView: 1,
    startAt: 0,
    autoplay: 4000,
    type: "slider"
};

export default function Banner () {
    useEffect(() => {
        const slider = new Glide('.glide', sliderConfiguration);
        slider.mount()
    }, [])
    return (
        <>
            <section className="banner">
                {/* <div className="banner-desktop">
                    <picture>
                        <source media="(min-width: 768px)" srcSet="/assets/imagens/misc/banner.png"/>
                        <img src="/assets/imagens/misc/bannersite-mobile.png" alt="Banner"/>
                    </picture>
                </div> */}
                <div className="glide">
                    <div className="glide__track" data-glide-el="track">
                        <ul className="glide__slides">
                            <li className="glide__slide slider">
                                    <img src="/assets/imagens/misc/banner.png" alt="imagem do carrossel"/>
                            </li>
                            <li className="glide__slide slider">
                                    <img src="/assets/imagens/misc/BANNERBLINDADOS1.png" alt="imagem do carrossel"/>
                            </li>
                            <li className="glide__slide slider">
                                    <img src="/assets/imagens/misc/banner2ParviImbracopiar.png" alt="imagem do carrossel"/>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="glide__arrows" data-glide-el="controls">
                        <a className="glide__arrow glide__arrow--left" data-glide-dir="<">
                            <img src="/misc/left.png" alt="" width={50} height={50}/>
                        </a>
                        <a className="glide__arrow glide__arrow--right" data-glide-dir=">">
                            <img src="/misc/right.png" alt="" width={50} height={50}/>
                        </a>
                    </div> */}
                </div>
            </section>


        </>
    )
};