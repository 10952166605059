import React, {useState} from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import MaskedInput from 'react-maskedinput'
import "./style.scss"

// const customStyles = {
//     content: {
//       width: '45%',
//       height: '410px',
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       marginRight: '-50%',
//       transform: 'translate(-50%, -50%)',
//     },
//   };


export default function Botao () {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [name, setName ] = useState('');
    const [email, setEmail ] = useState('');
    const [phone, setPhone ] = useState('');
    const [code, setCode ] = useState('8c7ba41f-6104-42d5-8a8d-cda5087043fa');

    function sendLead(e) {
      e.preventDefault();
      const data = {
        name,
        email,
        phone,
        code
      }
      axios.post('https://webservice.jogga.com.br/api/leads/webhook/joggahub',
        data
      )
      .then(function (response) {
        resetInfo();
      })
      .catch(function (error) {
        console.error(error);
      });
    }

    function resetInfo(){
      setName('');
      setEmail('');
      setPhone('');
    }

    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
    //   subtitle.style.color = '#f00';
    }
  
    function closeModal() {
      setIsOpen(false);
    }
    return (
        <>
                 
             <div className="botao">

                <button className="botao-desktop" onClick={openModal}>Quero saber mais</button>
                 
             </div>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                // style={customStyles}
                contentLabel="Example Modal"
                className='modal'
                overlayClassName='modal-overlay'
            >
                <div className='modal-button-close'><button onClick={closeModal} className='form-button-close'>X</button></div>
                <div className='box-modal'>
                  <div className='modal-div-titulo'>
                    <h2 className='modal-titulo'>Preencha o formulário e saiba mais sobre</h2>
                    <h2 className='modal-titulo'>todos os serviços da Parvi Blindados</h2>
                    </div>
                    <form onSubmit={sendLead}>
                        <div className='form-info'>
                            <label>Nome</label>    
                            <input
                            className='input-form'
                            type={'text'}
                            value={name}
                            required
                            onChange={e => setName(e.target.value)}
                            />

                            <label>E-mail</label>
                            <input
                            className='input-form'
                            type={'email'}
                            value={email}
                            required
                            onChange={e => setEmail(e.target.value)}
                            />

                            <label>Telefone</label>
                            <MaskedInput
                            className='input-form'
                            type={'text'}
                            required
                            mask='(11)11111-1111'
                            size='13'
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            />
                            <div className='div-form-button'><input type='submit' value='Enviar' className='form-button' /></div>
                        </div>
                    </form>
                </div>
                <div className='modal-privacidade'>Conheça nossa <strong>Política de Privacidade</strong></div>
            </Modal>
           
        </>
    )
};