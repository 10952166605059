import './style.scss'

export default function Rodape () {
    return (
        <>
            <section className='rodape-info'>
                <div className='rodape-azul'>
                    <img src='./assets/imagens/misc/rodape_1.png' className='nome-parvi'/>
                </div>
                <div className='local'>
                    <p className='rodape-local'>Av. Mal. Mascarenhas de Morais, 4555 - Imbiribeira, Recife - PE, 51150-004</p>
                    <p className='direitos'>Parvi Blindados, 2022. | Todos os direitos reservados.</p>
                </div>
            </section>
        </>
    )
}