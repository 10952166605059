import "./style.scss"

export default function Servicos () {
    return (
        <>
            <section className="servicos">
                <div className="servicos-desktop">
                    <img className="icon" src="/assets/imagens/misc/peso_1.png" />
                    <h3 className="titulo">Peso da blindagem</h3>
                    <div className="content">
                        <ul className="lista">
                            <li>+ Mantas de aramida</li>
                            <li>- Aço balístico</li>
                            <li><b>20% mais leve</b> do que a concorrência</li>
                            <li>Pesamos o veículo <b>antes e depois</b> da blindagem</li>
                        </ul>
                    </div>
                </div>
                <div className="servicos-desktop">
                    <img className="icon" src="/assets/imagens/misc/longo-prazo_1.png" />
                    <h3 className="titulo">Acabamento</h3>
                    <div className="content">
                        <ul className="lista">
                            <li>Vidros com <b>menos espessura</b></li>
                            <li><b>Excelente curvatura</b></li>
                            <li>Manutenção das características do carro</li>
                        </ul>
                    </div>
                </div>
                <div className="servicos-desktop">
                    <img className="icon" src="/assets/imagens/misc/tecnico_1.png" />
                    <h3 className="titulo">Corpo Técnico</h3>
                    <div className="content">
                        <ul className="lista">
                            <li>Profissionais qualificados com mais de <b>15 anos de experiência</b> no mercado</li>
                        </ul>
                    </div>
                </div>
                <div className="servicos-desktop">
                    <img className="icon" src="/assets/imagens/misc/seguro-de-automovel_1.png" />
                    <h3 className="titulo">Prazo de entrega</h3>
                    <div className="content">
                        <ul className="lista">
                            <li>Contamos com <b>diversos kits</b> em estoque</li>
                            <li><b>Menor prazo</b> de entrega da Região</li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
};