import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './style.scss'

export default function Carrossel () {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5,
          slidesToSlide: 5
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    return (
        <>
            <section>
                <div className='carrossel'>
                    <p className='carrossel-titulo'>Parceiros <strong>Grupo Parvi</strong></p>
                    <Carousel 
                        responsive={responsive}
                        showDots={true}
                        renderButtonGroupOutside={true}
                        // customButtonGroup={<ButtonGroup/>}
                        ssr={true}
                        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                        infinite={true}
                        dotListClass="custom-dot-list-style"
                        autoPlay={true}
                        autoPlaySpeed={3000}
                        transitionDuration={8000}
                        itemClass="carousel-item-padding-40-px"
                        >

                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/inbra.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/toyolex.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/fiori.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/agp.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/mlayer.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/protechtor.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">    
                            <img src="assets/imagens/misc/carrossel/yellow.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/america.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/audi.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/pateo.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/bremen.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/jaguar.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/mercedes.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/jeep.png" className="carrossel-img" />
                        </div>
                        <div className="carrossel-box">
                            <img src="assets/imagens/misc/carrossel/lexus.png" className="carrossel-img" />
                        </div>
                    </Carousel>
                </div>
            </section>
        </>
    )
};