import "./style.scss"

export default function Beneficios () {
    return (
        <>
            
            <section>
                <div className="beneficios">
                    <div className="imagem-beneficios"><img src="/assets/imagens/misc/car-left.png" alt="Imagem benefícios"/></div>

                    <div className="descritivo">
                        <p className="titulo-descritivo">Benefícios e vantagens <br/> de blindar o seu veículo <br/> na <strong>Parvi Blindados</strong></p>
                        <p className="titulo-descritivo-mobile">Benefícios e vantagens de <br/> blindar o seu veículo na <strong>Parvi <br/> Blindados</strong></p>

                        <p className="texto-descritivo">Uma empresa que faz parte do Grupo Parvi que há 30 anos atua no mercado automotivo no Norte e Nordeste e possui a mais alta tecnologia em blindagem combinada com a excelência na prestação de serviços.</p>

                        <p className="texto-descritivo">Temos parcerias com os melhores fornecedores de materiais do País, sempre levando em consideração as tecnologias e inovações para proporcionar uma excepcional instalação e acabamento final impecável.</p>

                        <p className="texto-descritivo">Somos uma empresa sólida desde a sua criação com mais de 1.000 veículos blindados. A nossa planta fica localizada na Imbiribeira, no prédio da Toyolex, com uma estrutura completa para blindagem e manutenção de veículos blindados.</p>
                    </div>
                    
                </div>
            </section>
        </>
    )
};