import { useState } from "react"
import "./style.scss"

export default function ServicosMobile () {
    const[isOpen, setIsOpen] = useState(false)
    const[isAcabamentoOpen, setIsAcabamentoOpen] = useState(false)
    const[isTecOpen, setIsTecOpen] = useState(false)
    const[isPrazoOpen, setIsPrazoOpen] = useState(false)

    return (
        <>
            <section className="dropdown-service">
                <div className='dropdown'>
                    <button className="dropdown-button" 
                        onClick={()=>{setIsOpen(!isOpen)}}
                    ><img src="/assets/imagens/misc/icon_white.png" alt="Ícone Peso" className="icon-info"/>Peso da Blindagem
                    <img src="/assets/imagens/misc/+.png" alt="Sinal de mais" className="sinal-mais"/></button>
                        {isOpen ? 
                        <div className="dropdown-content">
                            <p className="dropdown-text">+ Mantas de aramida</p>
                            <p className="dropdown-text">-  Aço balístico</p>
                            <p className="dropdown-text"><strong>20% mais leves</strong> do que a concorrência</p>
                            <p className="dropdown-text">Pesamos o veículo <strong>antes e depois</strong> da blindagem</p>
                        </div>
                        : ""
                        }
                        
                </div>

                <div className='dropdown'>
                    <button className="dropdown-button" 
                        onClick={()=>{setIsAcabamentoOpen(!isAcabamentoOpen)}}
                    ><img src="/assets/imagens/misc/seguro-de-automovel2.png" alt="Ícone Peso" className="icon-info"/>Acabamento
                    <img src="/assets/imagens/misc/+.png" alt="Sinal de mais" className="sinal-mais"/></button>
                        {isAcabamentoOpen ? 
                        <div className="dropdown-content">
                            <p className="dropdown-text"><strong>Vidros com</strong> menor espessura</p>
                            <p className="dropdown-text"><strong>Excelente curvatura</strong></p>
                            <p className="dropdown-text">Manutenção das características do carro</p>
                        </div>
                        : ""
                        }
                        
                </div>

                <div className='dropdown'>
                    <button className="dropdown-button" 
                        onClick={()=>{setIsTecOpen(!isTecOpen)}}
                    ><img src="/assets/imagens/misc/tecnico2.png" alt="Ícone Peso" className="icon-info"/>Corpo Técnico
                    <img src="/assets/imagens/misc/+.png" alt="Sinal de mais" className="sinal-mais"/></button>
                        {isTecOpen ? 
                        <div className="dropdown-content">
                            <p className="dropdown-text">Profissionais qualificados com mais de <strong>15 anos de experiência</strong> no mercado</p>
                        </div>
                        : ""
                        }
                        
                </div>

                <div className='dropdown'>
                    <button className="dropdown-button" 
                        onClick={()=>{setIsPrazoOpen(!isPrazoOpen)}}
                    ><img src="/assets/imagens/misc/longo-prazo1.png" alt="Ícone Peso" className="icon-info"/>Prazo de entrega
                    <img src="/assets/imagens/misc/+.png" alt="Sinal de mais" className="sinal-mais"/></button>
                        {isPrazoOpen ? 
                        <div className="dropdown-content">
                            <p className="dropdown-text"><strong>Menor prazo de entrega da região</strong></p>
                            <p className="dropdown-text">Contamos com <strong>diversos kits</strong> em estoque</p>
                        </div>
                        : ""
                        }
                        
                </div>
            </section> 
        </>
    )
};