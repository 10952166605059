import "./style.scss"
import Header from "../../components/Header"
import Rodape from "../../components/Rodape"

export default function QuemSomos() {
    return (
        <>
            <Header />
            <div className="wrapper">
                <div className="container">
                    <span>Sobre nós</span>
                    <hr />
                    <p>Alinhado à credibilidade do Grupo Parvi, a Parvi Blindados nasceu em 2015 com o objetivo de proporcionar segurança, conforto e tranquilidade para todos os nossos clientes, sempre oferecendo um elevado grau de proteção e qualidade durante todo o processo de blindagem. Hoje somos a principal referência no mercado de blindagem automotiva com uma equipe formada por profissionais treinados.</p>
                    <span>Certificação</span>
                    <hr />
                    <p>A PARVI é uma empresa que possui o selo da ABRABLIN e certificada pelos principais órgãos brasileiros e Exército, atentando a qualidade e segurança do serviço e de seus produtos.</p>
                </div>
                <div className="logos">
                    <img src="/assets/imagens/misc/abrablin.png" alt="abrablin" />
                    <img src="/assets/imagens/misc/exercito.png" alt="abrablin" />
                </div>
            </div>
            <Rodape />
        </>
    )
};