import React, { useEffect, useState } from 'react';
import './style.scss';
import Header from '../../components/Header';
import Rodape from '../../components/Rodape';
// import Glide from '@glidejs/glide'

const sliderConfiguration = {
    gap: 20,
    perView: 1,
    startAt: 0,
    type: "slider"
};

function Contato() {
    const [pagination, setPagination] = useState(0);

    // const slider = new Glide('.glide', sliderConfiguration);
    // useEffect(() => {
    //     return () => slider.mount()
    // }, [slider])
    const nextImage = () => {
        if(pagination === 3) {
            setPagination(0)
        } else {
            setPagination(pagination + 1)
        }
        console.log(pagination);
    }

    const prevImage = () => {
        if(pagination === 0) {
            setPagination(3)
        } else {
            setPagination(pagination - 1)
        }
    }

    return (
        <>
            <Header />
            <div className="wrapper">
                <div className="container">
                    <span>Contato</span>
                    <hr />
                    <div className="contact-container">
                        <div className="contact">
                            <span>Juvenal Cravo - Diretor</span>
                            <p>juvenal.neto@parviblindados.com.br
                                <br />(81) 9.9686.0668</p>
                        </div>
                        <div className="contact">
                            <span>Cidiny Gomes - Supervisor</span>
                            <p>compras@parviblindados.com.br
                                <br />(81) 9.8170.1962</p>
                        </div>
                        <div className="contact">
                            <span>Leandro Santos - Consultor Técnico Pós venda</span>
                            <p>pcp@parviblindados.com.br
                                <br />(81) 9.8652.9346</p>
                        </div>
                        <div className="contact">
                            <span>Alcilene Rodrigues - Assistente de Vendas</span>
                            <p>comercial@parviblindados.com.br
                                <br />(81) 9.9192.8487</p>
                        </div>
                    </div>
                    <span>Onde nos encontrar</span>
                    <hr />
                    <p>Av. Mal. Mascarenhas de Morais, 4555 - Imbiribeira, Recife - PE, 51150-004</p>
                </div>
                <div className="slider">

                    <div className="glide">
                        <div className="glide__track" data-glide-el="track">
                            <ul className="glide__slides">
                                <li className={`glide__slide slider ${pagination != 0 && 'hide'}`}>
                                    <img src="/assets/imagens/contact/mapa.png" alt="mapa" />
                                </li>
                                <li className={`glide__slide slider ${pagination != 1 && 'hide'}`}>
                                    <img src="/assets/imagens/contact/01.png" alt="mapa" />
                                </li>
                                <li className={`glide__slide slider ${pagination != 2 && 'hide'}`}>
                                    <img src="/assets/imagens/contact/02.png" alt="mapa" />
                                </li>
                                <li className={`glide__slide slider ${pagination != 3 && 'hide'}`}>
                                    <img src="/assets/imagens/contact/03.png" alt="mapa" />
                                </li>
                            </ul>
                        </div>
                        <div className="glide__arrows" data-glide-el="controls">
                            <div className="glide__arrow glide__arrow--left" data-glide-dir="<" >
                                <img src="/assets/imagens/misc/left.png" alt="arrow" onClick={() => prevImage()}/>
                            </div>
                            <div className="glide__arrow glide__arrow--right" data-glide-dir=">" >
                                <img src="/assets/imagens/misc/right.png" alt="arrow" onClick={() => nextImage()}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Rodape />
        </>
    );
}

export default Contato;

{/* <img src="/assets/imagens/contact/mapa.png" alt="mapa" /> */ }
