import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Twirl as Hamburger } from 'hamburger-react';
import "./style.scss"

export default function Header() {
    const [isOpen, setOpen] = useState(false)
    return (
        <>
            <header className="topo">
                <a href='https://www.parviblindados.com.br/'><img src="/assets/imagens/misc/logo.png" alt="logo do site" className='logoSite'></img></a>
                <div className="botoes">

                    <div className='dropdown'>
                        <button className="contato fixo">Fale conosco<img src="/assets/imagens/misc/icon-contato.png" className="icon" /></button>
                        <div className="dropdown-content">
                            <a href="tel:81991928487" className='number-link'>Vendas:<strong className='number-color'> (81) 99192-8487</strong></a>
                            <a href="tel:81986529346" className='number-link'>Pós Venda: <strong className='number-color'>(81) 98652-9346</strong></a>
                            <a href="tel:81991928487" className='number-link'>Vendas: <strong className='number-color'>(81) 99192-8487</strong></a>
                        </div>
                    </div>
                    <div className='whatsapp-contents'>
                        <a href='https://api.whatsapp.com/send?phone=5581991928487' target='_blanck'><button className="contato whats"><img src="/assets/imagens/misc/whatsapp.png" className="icon-whats" />WhatsApp</button></a>
                    </div>
                </div>

                <div className="menu">
                    <Hamburger toggled={isOpen} toggle={setOpen} />
                </div>


                <nav className={`navegador ${isOpen && "active"}`}>
                    <ul className="opcao">
                        <li className="lista">
                            <Link to="/" className="referencia">Home</Link>
                        </li>
                        <li className="lista">
                            <Link to="/quem-somos" className="referencia">Quem somos</Link>
                        </li>
                        <li className="lista">
                            <Link to="/contato" className="referencia">Nos encontre</Link>
                        </li>
                    </ul>
                </nav>
            </header>
        </>
    )
};