import "./style.scss"

export default function Pos () {
    return (
        <>
            <section className="pos">
                <div className="pos-venda">
            
                    <div className="descritivo">
                        <p className="titulo-venda">Pós venda</p>

                        <p className="texto-venda">A Parvi blindados oferece serviço de assistência técnica e um plano de manutenção com revisões periódicas para os veículos blindados.</p>

                        <p className="texto-venda">Oferecemos também o serviço especial de funilaria para veículos blindados. Entre em contato conosco e realize o agendamento.</p>
                    </div>

                    <div className="imagem-pos"><img src="/assets/imagens/misc/venda.png"/></div>
            
                </div>
            </section> 
        </>
    )
};