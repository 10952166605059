import logo from './logo.svg';
import './App.scss';
import Header from './components/Header';
import Banner from './components/Banner';
import Servicos from './components/Servicos';
import ServicosMobile from './components/ServicosMobile';
import Botao from './components/Botao';
import Beneficios from './components/Beneficios';
import Especialista from './components/Especialista';
import Marcas from './components/Marcas';
import Pos from './components/Pos';
import Carrossel from './components/Carrossel';
import BotaoMobile from './components/BotaoMobile';
import Rodape from './components/Rodape';

function App() {
  return (
    <div className="App">
      <Header/>
      <Banner/>
      <Servicos/>
      <ServicosMobile/>
      <Botao/>
      <Beneficios/>
      <Especialista/>
      <Marcas/>
      <Pos/>
      <Carrossel/>
      <BotaoMobile/>
      <Rodape/>
    </div>
  );
}

export default App;
