import React, {useState} from 'react';
import Modal from 'react-modal';
// import 'https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap';
import "./style.scss"


export default function BotaoMobile () {

    const[modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
      //   subtitle.style.color = '#f00';
      }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <>
            <section className="espaco-botao">
                <div className="mobile-content">
                    <button className="botao-mobile moble-contato" onClick={openModal}>
                        <img src="/assets/imagens/misc/contato-mobile.png" className="contato-mobile"/>
                    </button>
    
                    <a href='https://api.whatsapp.com/send?phone=5581991928487' target='_blank'><button className="botao-mobile moble-whatsapp">
                        <img src="/assets/imagens/misc/whatssmobile.png" className="whats-mobile"/>
                    </button>
                    </a>
                </div>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    contentLabel="Exemplo Modal"
                    overlayClassName='modal-overlay'
                    className='modal-mobile'
                >
                    <div className='modal-content'>
                        <div className='button-close'><button onClick={closeModal} className='close-button-modal-mobile'>X</button></div>
                        <h1 className='modal-title'>Fale Conosco</h1>
                        <p className='modal-info'>Telefone: <strong className='modal-number-mobile'>(81) 3338-8060</strong></p>
                        <p className='modal-info midium-info'>Pós Venda: <strong className='modal-number-mobile'>(81) 98652-9346</strong></p>
                        <p className='modal-info'>Vendas: <strong className='modal-number-mobile'>(81) 99192-8487</strong></p>
                    </div>
                </Modal>

            </section>
        </>
    )
};
