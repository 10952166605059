import "./style.scss"

export default function Especialista () {
    return (
        <>
            <section>
                <div className="especialista">
                    <h1 className="especialista-titulo">Especialistas em grandes marcas</h1>
                </div>
            </section>
        </>
    )
};